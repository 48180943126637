import { HTTP } from '@/App/Utils/axios'
import {
  FlespiConnection,
  NotificationsInfo,
  ScheduleReport,
  UserOnline,
  SMTPLog
} from '@/App/Services/interfaces/admin.interface'

export class AdminService {
  static async fetchUsersOnline(): Promise<UserOnline[]> {
    try {
      const { data } = await HTTP.get('/administrator/users-online')

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchFlespiConnections(): Promise<FlespiConnection[]> {
    try {
      const { data } = await HTTP.get('/administrator/flespi-connections')

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchScheduleReports(): Promise<ScheduleReport[]> {
    try {
      const { data } = await HTTP.get('/administrator/schedule-reports')

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchNotifications(): Promise<NotificationsInfo[]> {
    try {
      const { data } = await HTTP.get('/administrator/notifications')

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchSMTPLogs(from: number, to: number): Promise<SMTPLog[]> {
    try {
      const { data } = await HTTP.get('/administrator/smtp-logs', { params: { from, to } })

      return data
    } catch (e) {
      throw e
    }
  }
}
