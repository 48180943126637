import { Component, Vue } from 'vue-property-decorator'
import { NotificationsInfo } from '@/App/Services/interfaces/admin.interface'
import { AdminService } from '@/App/Services/Admin.service'

@Component
export default class NotificationsPage extends Vue {
  public isLoading = false
  public search = ''
  public headers = [
    { text: 'User', align: 'left', sortable: true, value: 'user' },
    { text: 'Notification name', align: 'left', sortable: true, value: 'notificationName' },
    { text: 'Type', align: 'left', sortable: true, value: 'type' },
    { text: 'Days Of Week', align: 'left', sortable: true, value: 'daysOfWeek' },
    { text: 'Last notification', align: 'left', sortable: true, value: 'lastNotificationTime' }
  ]
  public tableData: NotificationsInfo[] = []

  private async mounted() {
    try {
      this.isLoading = true

      this.tableData = await AdminService.fetchNotifications()
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }
}
